import React from 'react'
import { Box, Grid, makeStyles } from '@material-ui/core'
import styled from 'styled-components'
import Image from 'components/Image'
import RemarkRenderer from 'components/RemarkRenderer'
import { layouts } from 'utils/cms-values'
import themeColor from 'utils/themeColor'

// ===================================================
// STYLES
// ===================================================

const Container = styled('div')({
  width: '100%',
  padding: '40px 24px',
  background: ({ background, theme }) => themeColor(background, null, theme),
})

const ColumnsWrapper = styled('div')({
  width: '100%',
  maxWidth: 'calc(1600px - 48px)',
  margin: 'auto',
  background: ({ columnsBackground, theme }) =>
    themeColor(columnsBackground, null, theme),
})

const Heading = styled('h3')({
  fontSize: 28,
  lineHeight: '34px',
  fontWeight: 'bold',
  marginBottom: 16,
  color: ({ color, theme }) => themeColor(color, 'darkGray', theme),
  '@media (max-width: 960px)': {
    wordBreak: 'break-word', // fix overflow issues
  },
})

const TextContent = styled('div')({
  fontSize: 16,
  lineHeight: '24px',
  marginBottom: 24,
  color: ({ color, theme }) => themeColor(color, 'darkGray', theme),
})

const Button = styled('a')({
  border: 'none',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: 13,
  padding: '12px 24px',
  background: ({ color, theme }) => themeColor(color, 'orange', theme),
  color: ({ textColor, theme }) => themeColor(textColor, 'white', theme),
})

const useStyles = makeStyles(theme => ({
  columns: {
    width: '100%',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  imageContainer: {
    position: 'relative',
    flex: 1,
    height: '100%', // remove this to force always use aspectRatio (otherwise image will adjust aspect ratio to fill text area height when it is too long)
    paddingBottom: ({ aspectRatio }) => aspectRatio,
    marginLeft: ({ reverse, padCenter }) => (reverse && padCenter ? 40 : 0),
    marginRight: ({ reverse, padCenter }) => (!reverse && padCenter ? 40 : 0),
    [theme.breakpoints.down('sm')]: {
      '&&': {
        marginLeft: 0,
        marginRight: 0,
      },
    },
  },
  textContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(10, 5),
    marginLeft: ({ reverse, padCenter }) => (!reverse && padCenter ? 40 : 0),
    marginRight: ({ reverse, padCenter }) => (reverse && padCenter ? 40 : 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
      '&&': {
        marginLeft: 0,
        marginRight: 0,
      },
    },
  },
}))

// ===================================================
// COMPONENTS
// ===================================================

const StoryblokColumnsImageAndText = ({ data }) => {
  const {
    image,
    heading,
    headingColor,
    textContent,
    textContentColor,
    button,
    reverse,
    columnsLayout = 'equal',
    aspectRatio = 'square',
    background,
    columnsBackground,
  } = data
  const padCenter = columnsLayout === 'equal'
  const sizes = layouts[columnsLayout]
  const classes = useStyles({ aspectRatio, reverse, padCenter })
  return (
    <Container background={background}>
      <ColumnsWrapper columnsBackground={columnsBackground}>
        <Grid
          container
          direction={reverse ? 'row-reverse' : 'row'}
          spacing={0}
          className={classes.columns}
        >
          <Grid item xs={12} md={sizes.image} className={classes.column}>
            <Box className={classes.imageContainer}>
              {image?.filename && (
                <Image background src={image?.filename} alt={image?.alt} />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={sizes.text} className={classes.column}>
            <Box className={classes.textContainer}>
              <Box className={classes.richText}>
                <Heading color={headingColor}>{heading}</Heading>
                <TextContent color={textContentColor}>
                  <RemarkRenderer
                    ast={textContent?.childMarkdownRemark?.htmlAst}
                  />
                </TextContent>
                {button?.label && (
                  <Button {...button} href={button?.anchor}>
                    {button.label}
                  </Button>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ColumnsWrapper>
    </Container>
  )
}

export default StoryblokColumnsImageAndText
