// ===================================================
// A collection of values to map from CMS values to JS/CSS that
// are reused in multiple components
// ===================================================

export const aspectRatios = {
  natural: 'initial',
  square: '100%',
  crtTV: '75%',
  widescreen: '56.25%',
  letterbox: '40%',
}

export const layouts = {
  largeImage: {
    image: 7,
    text: 5,
  },
  largeText: {
    image: 5,
    text: 7,
  },
  equal: {
    text: 6,
    image: 6,
  },
}
